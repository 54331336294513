import React from "react";
import { Page } from "../../components/Page";
import { Title } from "../../components/Title";
import { Content } from "../../components/Content";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const OuluPage = () => (
  <Page page="Oulu">
    <Row>
      <Col>
        <Title>Näin ilmoittaudut varoitettavaksi Ouluun</Title>

        <Content>
          Lähetä tekstiviesti numeroon 16130 siitä puhelimesta, johon haluat
          varoituksen tulevan:
        </Content>

        <Content as="ol">
          <li>
            Kirjoita viestiin sanat <b>varoitus oulu</b>
          </li>
          <li>
            Kirjoita loppuun joko <b>heti, 6, 7, 8 tai 9</b> sen mukaan haluatko
            varoituksen yöllä syntyneestä liukkaudesta heti kun varoitus on
            annettu, klo 6, klo 7, klo 8 vai klo 9 (viikonloppuisin tuntia
            myöhemmin)
          </li>
          <li>
            Jos esimerkiksi haluat yöllä syntyneestä liukkaudesta varoituksen
            aamulla klo 7, lähetät tekstiviestin: <b>varoitus oulu 7</b>
          </li>
        </Content>

        <Content>
          Ilmoittautumisesi pysyy voimassa tulevinakin talvikausina. Mikäli
          haluat poistua palvelusta, lähetä numeroon <b>16130</b> tekstiviesti:{" "}
          <b>varoitus sulje</b>
        </Content>

        <Content>
          Palveluun liittyminen ja varoitusviestien vastaanottaminen on palvelun
          käyttäjälle maksutonta.
        </Content>

        <Content>
          Mikäli haluat muuttaa yöllä syntyneen liukkauden varoituksen
          vastaanottoaikaa, täytyy sinun ilmoittautua uudelleen uudella
          kellonajalla.
        </Content>
      </Col>
    </Row>
  </Page>
);

export default OuluPage;
